import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css' // 样式重置

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
      document.title = to.meta.title
  }
  if (to.meta.content) {
      let head = document.getElementsByTagName('head');
      let meta = document.createElement('meta');
      let keywords = document.querySelector('meta[name="keywords"]');
      let description = document.querySelector('meta[name="description"]');
      keywords && keywords.setAttribute('content', to.meta.content.keywords);
      description && description.setAttribute('content', to.meta.content.description);
      meta.content = to.meta.content;
      head[0].appendChild(meta)
  }
  next()
})