import Vue from 'vue'
import Router from 'vue-router'
import layout from '@/components/layout'
Vue.use(Router)

export default new Router({
    mode: 'history',
    scrollBehavior (){
        return {x:0, y:0}
    },
    routes: [
        {
            path:'/',
            meta: { title: '首页' },
            redirect: '/',
            component: layout,
            children: [
                {
                    path: '/',
                    meta: {
                        title: '共享充电宝招商加盟-速绿充电官网',
                        content:{
                            keywords:"速绿,速绿官网,速绿充电,速绿充电宝,共享充电宝,充电宝,招商加盟",
                            description:"速绿，总部坐落于北京，是国内领先的共享充电平台。网点遍布全国，以智能充电宝租赁终端设备，实现手机随时随地免押借用充电宝，让你的手机永不断电。",
                        }
                    },
                    component: resolve => require(['@/pages/home'], resolve)
                },
                {
                    path: 'product',
                    meta: {
                        title: '共享充电宝采购经销-速绿充电官网',
                        content:{
                            keywords:"共享充电宝,共享充电宝代理,共享充电宝加盟,共享充电宝采购,共享充电排行",
                            description:"速绿共享充电宝,是国内领先的共享充电平台。网点遍布全国,以智能充电宝租赁终端设备,实现手机随时随地免押借用充电宝,让你的手机永不断电。",
                        }
                    },
                    component: resolve => require(['@/pages/product'], resolve)
                },
                {
                    path: 'agent',
                    meta: {
                        title: '共享充电宝代理加盟招商-速绿充电官网',
                        content:{
                            keywords:"共享充电宝,共享充电宝代理,共享充电宝加盟,共享充电宝采购,共享充电排行",
                            description:"速绿共享充电宝广邀加盟代理，速绿拥有智能化工厂及专业研发团队，获共享充电宝多项专利认证，投资门槛低，回报率高，网点遍布全国，是不可多得的好项目。",
                        }
                    },
                    component: resolve => require(['@/pages/agent'], resolve)
                },
                {
                    path: 'trends',
                    meta: {
                        title: '速绿共享充电新闻活动-速绿充电官网',
                        content:{
                            keywords:"共享充电宝,共享充电宝代理,共享充电宝加盟,共享充电宝采购,共享充电排行",
                            description:"打造国内领先的共享充电宝平台，以智能充电宝租赁终端设备,实现手机随时随地免押借用充电宝,让你的手机永不断电。",
                        }
                    },
                    component: resolve => require(['@/pages/trends'], resolve)
                },
                {
                    path: 'strength',
                    meta: {
                        title: '共享充电宝排名品牌实力-速绿充电官网',
                        content:{
                            keywords:"共享充电宝,共享充电宝代理,共享充电宝加盟,共享充电宝采购,共享充电排行",
                            description:"速绿是共享充电宝行业领航品牌，为用户提供优质的共享充电服务，网点遍布全国，速绿拥有智能化工厂及专业研发团队，获共享充电宝多项专利认证，公司背景实力雄厚。",
                        }
                    },
                    component: resolve => require(['@/pages/strength'], resolve)
                }
            ]
        }
    ]
})

//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}