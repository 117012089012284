<template>
  <div id="layout">
    <header-view></header-view>
    <div class="main">
        <router-view />
    </div>
    <footer-view></footer-view>
  </div>
</template>

<script>
import headerView from './header'
import footerView from './footer'
export default {
  data () {
    return {
    };
  },
  components: {headerView , footerView},
  mounted () {},
  computed: {},
  methods: {}
}
</script>

<style lang='scss' scoped>
#layout{
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 1200px;
}
.main{
  position: relative;
  padding-top: 70px;
  width: 100%;
  min-height: 700px;
  box-sizing: border-box;
  display: flex;
}
</style>