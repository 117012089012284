<template>
  <div class="footerView">
    <div class="footer_contentView">
      <div class="footer_contentView_t">
        <div class="footer_contentView_l">
          <div class="footer_list" v-for="group in toolItems" :key="group.title">
            {{ group.title }}
            <div class="pointer" v-for="item in group.items" :key="item" @click="navBtnTouch(group.path,item)">
              {{ item }}
            </div>
          </div>
        </div>
        <div class="footer_contentView_r">
          4000-519-000
          <div>周一至周日 9:00-18:00</div>
          <div class="QRCode"></div>
        </div>
      </div>
      <div class="footer_contentView_b">
        <div class="copyright">
          <div class="pointer" @click="pushUrl">琼ICP备20001387号-1</div>
          <div>海南掌上能量传媒有限公司</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toolItems:[
        {
          title: '产品中心',
          items: ['MINI机','充电宝','大型机','蓝牙线充'],
          path:'product'
        },{
          title: '代理合作',
          items: ['业务介绍','代理申请'],
          path:'agent'
        },{
          title: '服务与支持',
          items: ['新闻资讯','公司新闻','媒体报道','活动中心'],
          path:'trends'
        },{
          title: '关于我们',
          items: ['公司简介','团队介绍','招聘信息'],
          path:'strength'
        },{
          title: '联系我们',
          items: ['联系方式'],
          path:'agent'
        }
      ]
    };
  },
  components: {},
  mounted() {
    // if(window.location.host.indexOf("vsulv.com") != -1){
    //   this.recordsCode = "琼ICP备20001387号-1"
    // }else{
    //   this.recordsCode = "琼ICP备20001387号-2"
    // }
  },
  methods: {
    navBtnTouch(path,name){
      if(name == '代理申请'){
        window.open("https://jinshuju.net/f/cZtdMb","_blank")
      }else{
        const route = this.$route;
        const pathArr = route.path.split("/");
        if(pathArr[1] == path){
          window.scrollTo(0, 0);
        }else{
          this.$router.push(path);
        }
      }
    },
    pushUrl() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index","_blank")
    },
  },
};
</script>

<style lang='scss' scoped>
.footerView {
  display: flex;
  justify-content: center;
  background-color: #232627;
  color: #FEFFFF;
  font-weight: 600;
}
.footer_contentView {
  padding: 50px 0 70px 0;
  width: 1200px;
}
.footer_contentView_t {
  display: flex;
  justify-content: space-between;
}
.footer_contentView_l {
  width: 700px;
  display: flex;
  justify-content: space-between;
}
.footer_list {
  font-size: 15px;
  text-align: left;
}
.footer_list div {
  font-size: 11px;
  margin-top: 16px;
  color: #C4C5C7;
}
.footer_contentView_r {
  font-size: 35px;
  border-left: 3px solid #3d4242;
  padding: 0 30px;
}
.footer_contentView_r div {
  text-align: center;
  font-size: 13px;
  color: #C4C5C7;
}
.QRCode{
  width: 130px;
  height: 130px;
  background-image: url("../assets/img/code.jpg");
  background-size: 100% 100%;
  margin: 40px auto 0 auto;
}
.footer_contentView_b {
  font-size: 13px;
  margin-top: 40px;
  color: #C4C5C7;
}
.pointer{
  cursor: pointer;
}
.copyright {
  text-align: center;
  border-top: 3px solid #3d4242;
  div{
    font-size: 13px;
    width: 200px;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
  }
}
</style>