<template>
  <div class="header">
    <div class="contentView">
      <div class="icon"></div>
      <div class="titleNav pointer"
        v-for="nav in navObjs" 
        :key="nav.title"
        :style="{color:activeNav==nav.path?'#29A547':'black'}"
        @click="navBtnTouch(nav)"
      >{{nav.title}}</div>
      <div class="phoneView"><div class="phoneIcon"></div>服务热线：4000-519-000</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navObjs:[
        {
          title:'首页',
          path:'/'
        },
        {
          title:'产品介绍',
          path:'product'
        },
        {
          title:'合作申请',
          path:'agent'
        },
        {
          title:'公司动态',
          path:'trends'
        },
        {
          title:'企业实力',
          path:'strength'
        },
      ]
    };
  },
  components: {},
  mounted () {},
  computed: {
    activeNav: function () {
      const route = this.$route;
      const pathArr = route.path.split("/");
      const path = pathArr[1]==''?'/':pathArr[1];
      return path;
    },
  },
  methods: {
    navBtnTouch(nav) {
      this.navSel = nav.title;
      this.$router.push(nav.path);
    },
  }
}
</script>

<style lang='scss' scoped>
.pointer {
  cursor: pointer;
}
.header{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  color: black;
  z-index: 1000;
  background: white;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 0 10px #ddd;
}
.contentView{
  width: 1200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
}
.icon{
  width: 80px;
  height: 55px;
  background-image: url("../assets/img/sulv_logo.png");
  background-size: 100% 100%;
}
.phoneView{
  display: flex;
  align-items: center;
}
.phoneIcon{
  width: 20px;
  height: 20px;
  background-image: url("../assets/img/phone.jpg");
  background-size: 100% 100%;
  margin-right: 4px;
}
.titleNav{
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  padding: 20px;
}
</style>
