import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  id: '0'
}
const mutations = {
  setID(state,id){
    state.id = id
  }
}

const store = new Vuex.Store({
  state,
  mutations
})
export default store